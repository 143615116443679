import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, Scatter } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const formatCost = (cost) => {
  return `$${cost.toFixed(5)}`;
};

const formatNumber = (num) => num.toLocaleString();

const ModelStatsCard = ({ type, stats }) => {
  return (
    <div className="col-md-6 mb-4">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">{type === 'conversation' ? 'Conversation (Mini)' : 'Feedback'} Stats</h5>
        </div>
        <div className="card-body">
          <p>Total Tokens: {formatNumber(stats.totalTokens[type])}</p>
          <p>Total Cost: ${stats.totalCost[type].toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

const CombinedCostDistributionCard = ({ stats }) => {
  const parts = ['1', '2', '3'];
  
  const data = {
    labels: parts.map(part => `Part ${part}`),
    datasets: [
      // Conversation costs
      {
        label: 'Conversation - Text Input',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.text.input),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',  // blue
      },
      {
        label: 'Conversation - Audio Input',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.audio.input),
        backgroundColor: 'rgba(153, 102, 255, 0.5)',  // purple
      },
      {
        label: 'Conversation - Text Cached',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.text.cached),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',  // pink
      },
      {
        label: 'Conversation - Audio Cached',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.audio.cached),
        backgroundColor: 'rgba(255, 205, 86, 0.5)',  // yellow
      },
      {
        label: 'Conversation - Text Output',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.text.output),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',  // teal
      },
      {
        label: 'Conversation - Audio Output',
        data: parts.map(part => stats.parts[part].conversation.averages.costs.audio.output),
        backgroundColor: 'rgba(255, 159, 64, 0.5)',  // orange
      },
      // Feedback costs (completely different colors, same opacity)
      {
        label: 'Feedback - Text Input',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.text.input),
        backgroundColor: 'rgba(141, 211, 199, 0.5)',  // soft teal
        borderWidth: 0,  // Explicitly set border width to 0
      },
      {
        label: 'Feedback - Audio Input',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.audio.input),
        backgroundColor: 'rgba(251, 128, 114, 0.5)',  // salmon
        borderWidth: 0,
      },
      {
        label: 'Feedback - Text Cached',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.text.cached),
        backgroundColor: 'rgba(128, 177, 211, 0.5)',  // steel blue
        borderWidth: 0,
      },
      {
        label: 'Feedback - Audio Cached',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.audio.cached),
        backgroundColor: 'rgba(253, 180, 98, 0.5)',  // light orange
        borderWidth: 0,
      },
      {
        label: 'Feedback - Text Output',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.text.output),
        backgroundColor: 'rgba(179, 222, 105, 0.5)',  // lime green
        borderWidth: 0,
      },
      {
        label: 'Feedback - Audio Output',
        data: parts.map(part => stats.parts[part].feedback.averages.costs.audio.output),
        backgroundColor: 'rgba(252, 205, 229, 0.5)',  // light pink
        borderWidth: 0,
      },
    ]
  };

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Average Cost per Exercise by Part (Combined)</h2>
        
        {/* Combined Cost Distribution Chart */}
        <div className="mb-4">
          <Bar
            data={data}
            options={{
              responsive: true,
              scales: {
                x: { stacked: true },
                y: { 
                  stacked: true,
                  title: {
                    display: true,
                    text: 'Cost in USD'
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `${context.dataset.label}: ${formatCost(context.raw)}`;
                    }
                  }
                },
                legend: {
                  position: 'right',
                  align: 'start',
                  labels: {
                    boxWidth: 20,
                    borderWidth: 0  // Remove border from legend items
                  }
                }
              }
            }}
          />
        </div>

        {/* Combined Cost Table */}
        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Part</th>
                <th>Model</th>
                <th>Text Input</th>
                <th>Audio Input</th>
                <th>Text Cached</th>
                <th>Audio Cached</th>
                <th>Text Output</th>
                <th>Audio Output</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {parts.flatMap(part => {
                const models = ['conversation', 'feedback'];
                
                // Calculate totals for this part
                const partTotals = {
                  text: { input: 0, cached: 0, output: 0 },
                  audio: { input: 0, cached: 0, output: 0 },
                  total: 0
                };

                // Get rows for both models and calculate totals
                const modelRows = models.map(model => {
                  const partStats = stats.parts[part][model];
                  const totalCost = 
                    partStats.averages.costs.text.input +
                    partStats.averages.costs.audio.input +
                    partStats.averages.costs.text.cached +
                    partStats.averages.costs.audio.cached +
                    partStats.averages.costs.text.output +
                    partStats.averages.costs.audio.output;

                  // Add to part totals
                  partTotals.text.input += partStats.averages.costs.text.input;
                  partTotals.text.cached += partStats.averages.costs.text.cached;
                  partTotals.text.output += partStats.averages.costs.text.output;
                  partTotals.audio.input += partStats.averages.costs.audio.input;
                  partTotals.audio.cached += partStats.averages.costs.audio.cached;
                  partTotals.audio.output += partStats.averages.costs.audio.output;
                  partTotals.total += totalCost;

                  return (
                    <tr key={`${part}-${model}`}>
                      <td>{model === 'conversation' ? `Part ${part}` : ''}</td>
                      <td>{model === 'conversation' ? 'GPT-4-Mini' : 'GPT-4'}</td>
                      <td>{formatCost(partStats.averages.costs.text.input)}</td>
                      <td>{formatCost(partStats.averages.costs.audio.input)}</td>
                      <td>{formatCost(partStats.averages.costs.text.cached)}</td>
                      <td>{formatCost(partStats.averages.costs.audio.cached)}</td>
                      <td>{formatCost(partStats.averages.costs.text.output)}</td>
                      <td>{formatCost(partStats.averages.costs.audio.output)}</td>
                      <td>{formatCost(totalCost)}</td>
                    </tr>
                  );
                });

                // Add total row for this part
                const totalRow = (
                  <tr key={`${part}-total`} className="table-secondary">
                    <td></td>
                    <td><strong>Total Part {part}</strong></td>
                    <td><strong>{formatCost(partTotals.text.input)}</strong></td>
                    <td><strong>{formatCost(partTotals.audio.input)}</strong></td>
                    <td><strong>{formatCost(partTotals.text.cached)}</strong></td>
                    <td><strong>{formatCost(partTotals.audio.cached)}</strong></td>
                    <td><strong>{formatCost(partTotals.text.output)}</strong></td>
                    <td><strong>{formatCost(partTotals.audio.output)}</strong></td>
                    <td><strong>{formatCost(partTotals.total)}</strong></td>
                  </tr>
                );

                return [...modelRows, totalRow];
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const SignupCodesSection = () => {
  const [codes, setCodes] = useState([]);
  const [newCode, setNewCode] = useState({
    code: '',
    description: '',
    exerciseLimits: { part1: 0, part2: 0, part3: 0 }
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCodeStats();
  }, []);

  const fetchCodeStats = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/admin/signup-codes/stats', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCodes(response.data);
    } catch (err) {
      setError('Failed to fetch signup code statistics');
    }
  };

  const handleCreateCode = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/admin/signup-codes', newCode, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNewCode({
        code: '',
        description: '',
        exerciseLimits: { part1: 0, part2: 0, part3: 0 }
      });
      fetchCodeStats();
    } catch (err) {
      setError('Failed to create signup code');
    }
  };

  const handleToggleCode = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`/api/admin/signup-codes/${id}/toggle`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchCodeStats();
    } catch (err) {
      setError('Failed to toggle signup code');
    }
  };

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-title mb-0">Signup Codes</h5>
      </div>
      <div className="card-body">
        {error && <div className="alert alert-danger">{error}</div>}
        
        {/* Create new code form */}
        <form onSubmit={handleCreateCode} className="mb-4">
          <div className="row g-3">
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                placeholder="Code"
                value={newCode.code}
                onChange={(e) => setNewCode(prev => ({ ...prev, code: e.target.value }))}
                required
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                value={newCode.description}
                onChange={(e) => setNewCode(prev => ({ ...prev, description: e.target.value }))}
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                className="form-control"
                placeholder="Part 1 Limit"
                value={newCode.exerciseLimits.part1}
                onChange={(e) => setNewCode(prev => ({
                  ...prev,
                  exerciseLimits: { ...prev.exerciseLimits, part1: parseInt(e.target.value) }
                }))}
                required
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                className="form-control"
                placeholder="Part 2 Limit"
                value={newCode.exerciseLimits.part2}
                onChange={(e) => setNewCode(prev => ({
                  ...prev,
                  exerciseLimits: { ...prev.exerciseLimits, part2: parseInt(e.target.value) }
                }))}
                required
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                className="form-control"
                placeholder="Part 3 Limit"
                value={newCode.exerciseLimits.part3}
                onChange={(e) => setNewCode(prev => ({
                  ...prev,
                  exerciseLimits: { ...prev.exerciseLimits, part3: parseInt(e.target.value) }
                }))}
                required
              />
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary">Create Code</button>
            </div>
          </div>
        </form>

        {/* Codes table with statistics */}
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Part 1</th>
                <th>Part 2</th>
                <th>Part 3</th>
                <th>Users</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {codes.map(code => (
                <tr key={code._id}>
                  <td>{code.code}</td>
                  <td>{code.description}</td>
                  <td>{code.exerciseLimits.part1}</td>
                  <td>{code.exerciseLimits.part2}</td>
                  <td>{code.exerciseLimits.part3}</td>
                  <td>
                    <span className="badge bg-secondary">
                      {code.usersCount} users
                    </span>
                  </td>
                  <td>
                    <span className={`badge bg-${code.isActive ? 'success' : 'danger'}`}>
                      {code.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => handleToggleCode(code._id)}
                    >
                      {code.isActive ? 'Deactivate' : 'Activate'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const AdminPage = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/admin/stats', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStats(response.data);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger m-4" role="alert">
        {error}
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Admin Dashboard</h1>
      
      {/* Signup Codes Section */}
      <SignupCodesSection />
      
      <h2 className="mb-4">Usage Statistics</h2>
      
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Overview</h5>
            </div>
            <div className="card-body">
              <p>Total Exercises: {stats.totalExercises}</p>
            </div>
          </div>
        </div>
        
        <ModelStatsCard type="conversation" stats={stats} />
        <ModelStatsCard type="feedback" stats={stats} />
      </div>

      <h2 className="mb-3">Cost Analysis</h2>
      <CombinedCostDistributionCard stats={stats} />
    </div>
  );
};

export default AdminPage;
