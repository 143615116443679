import React from "react";
import { useTranslation } from "react-i18next";

const CueCard = ({ topic, points }) => {
  const { t } = useTranslation();
  
  return (
    <div className="card h-100 shadow" style={{ borderRadius: '1rem', overflow: 'hidden' }}>
      <div style={{ backgroundColor: '#2f56bc', padding: '1rem' }}>
        <div className="d-flex align-items-center">
          <h6 className="mb-0 text-white">
            <i className="bi bi-card-text me-2"></i>
            {t('cue_card')}
          </h6>
        </div>
      </div>
      <div className="p-4">
        <div className="mb-4 fw-bold text-center">{topic}</div>
        <div className="d-flex justify-content-center">
          <ul className="list-unstyled m-0" style={{ width: 'fit-content' }}>
            {points.map((point, index) => (
              <li key={index} className="mb-2">
                <div className="d-flex">
                  <span className="me-2 fw-bold">•</span>
                  <span className="fw-bold">{point}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="text-muted mt-4" style={{ fontSize: '0.85em' }}>
          <p className="mb-0">You will have to talk about the topic for one to two minutes.</p>
          <p className="mb-0">You have one minute to think about what you're going to say.</p>
          <p className="mb-0">You can make some notes to help you if you wish.</p>
        </div>
      </div>
    </div>
  );
};

export default CueCard;
