import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { useTranslation } from "react-i18next";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const PerformanceChart = ({ resetTrigger, inline = false }) => {
  const { t } = useTranslation();
  const [performanceData, setPerformanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMetrics, setSelectedMetrics] = useState(['overall']);

  const fetchData = async () => {
    try {
      const response = await fetch('/api/exercises/performance', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setPerformanceData(data);
        if (data.length > 0) {
          setSelectedMetrics(['overall', 'fluency', 'vocabulary', 'grammar', 'pronunciation']);
        }
      } else {
        setError('Failed to fetch performance data');
      }
    } catch (error) {
      setError('Error fetching performance data');
      console.error('Error fetching performance data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchData();
  }, []);

  // Refetch when feedback is received (prop changes)
  useEffect(() => {
    if (resetTrigger) {
      fetchData();
    }
  }, [resetTrigger]);

  if (isLoading) {
    return <div className={inline ? "text-center" : "card p-3 mb-4 text-center"}>{t('loading')}...</div>;
  }

  if (error) {
    return <div className={inline ? "text-center text-danger" : "card p-3 mb-4 text-center text-danger"}>{error}</div>;
  }

  if (performanceData.length === 0) {
    return null;
  }

  const getLatestScores = () => {
    if (performanceData.length === 0) return null;
    const latest = performanceData[performanceData.length - 1].scores;
    const previous = performanceData.length > 1 
      ? performanceData[performanceData.length - 2].scores 
      : null;
    
    return {
      overall: {
        score: latest.overall,
        change: previous ? latest.overall - previous.overall : 0
      },
      fluency: {
        score: latest.fluency,
        change: previous ? latest.fluency - previous.fluency : 0
      },
      vocabulary: {
        score: latest.vocabulary,
        change: previous ? latest.vocabulary - previous.vocabulary : 0
      },
      grammar: {
        score: latest.grammar,
        change: previous ? latest.grammar - previous.grammar : 0
      },
      pronunciation: {
        score: latest.pronunciation,
        change: previous ? latest.pronunciation - previous.pronunciation : 0
      }
    };
  };

  const latestScores = getLatestScores();

  const colors = {
    overall: { border: 'rgb(47, 86, 188)', background: 'rgba(47, 86, 188, 0.1)' },
    fluency: { border: 'rgb(75, 192, 192)', background: 'rgba(75, 192, 192, 0.1)' },
    vocabulary: { border: 'rgb(255, 159, 64)', background: 'rgba(255, 159, 64, 0.1)' },
    grammar: { border: 'rgb(153, 102, 255)', background: 'rgba(153, 102, 255, 0.1)' },
    pronunciation: { border: 'rgb(255, 99, 132)', background: 'rgba(255, 99, 132, 0.1)' }
  };

  const processedData = performanceData.reduce((acc, item) => {
    const date = new Date(item.date).toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric'
    });
    
    acc.labels.push(date);
    acc.datasets.overall.push(item.scores.overall);
    acc.datasets.fluency.push(item.scores.fluency);
    acc.datasets.vocabulary.push(item.scores.vocabulary);
    acc.datasets.grammar.push(item.scores.grammar);
    acc.datasets.pronunciation.push(item.scores.pronunciation);
    
    return acc;
  }, {
    labels: [],
    datasets: {
      overall: [],
      fluency: [],
      vocabulary: [],
      grammar: [],
      pronunciation: []
    }
  });

  const chartData = {
    labels: processedData.labels,
    datasets: Object.entries({
      overall: t('overall'),
      fluency: t('fluency'),
      vocabulary: t('vocabulary'),
      grammar: t('grammar'),
      pronunciation: t('pronunciation')
    }).map(([key, label]) => ({
      label,
      data: processedData.datasets[key],
      borderColor: colors[key].border,
      backgroundColor: colors[key].background,
      fill: false,
      tension: 0.3,
      borderWidth: 1.5,
      pointRadius: 2,
      pointHoverRadius: 4,
      hidden: !selectedMetrics.includes(key)
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        onClick: (e, legendItem) => {
          const metric = Object.keys({
            overall: t('overall'),
            fluency: t('fluency'),
            vocabulary: t('vocabulary'),
            grammar: t('grammar'),
            pronunciation: t('pronunciation')
          })[legendItem.datasetIndex];
          
          setSelectedMetrics(prev => 
            prev.includes(metric) 
              ? prev.filter(m => m !== metric)
              : [...prev, metric]
          );
        },
        labels: {
          usePointStyle: true,
          padding: 15,
          font: {
            size: window.innerWidth > 768 ? 13 : 11
          }
        }
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: '#666',
        bodyColor: '#666',
        borderColor: '#eee',
        borderWidth: 1,
        padding: 8,
        boxPadding: 3,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(1)}`;
          }
        }
      }
    },
    scales: {
      y: {
        // min: 0,
        // max: 9,
        ticks: {
          stepSize: 1,
          color: '#999',
          font: {
            size: window.innerWidth > 768 ? 13 : 10
          },
          padding: 8
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.03)',
          drawBorder: false
        },
        border: {
          display: false
        }
      },
      x: {
        ticks: {
          color: '#999',
          font: {
            size: window.innerWidth > 768 ? 13 : 10
          },
          padding: 8
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    animation: {
      duration: 800
    }
  };

  const ScoreCard = ({ label, score, change, color }) => (
    <div className="col">
      <div 
        className="card h-100 border-0"
        style={{ 
          backgroundColor: color.background,
          borderLeft: `3px solid ${color.border}`,
          transition: 'all 0.3s ease'
        }}
      >
        <div className="card-body p-3 py-2">
          <div className="text-secondary mb-1" style={{ fontSize: '0.8rem', letterSpacing: '0.05em' }}>
            {label}
          </div>
          <div className="d-flex align-items-baseline gap-2">
            <div className="fs-3 fw-medium">{score.toFixed(1)}</div>
            {change !== 0 && (
              <div 
                className={`small ${change > 0 ? 'text-success' : 'text-danger'}`}
                style={{ fontSize: '0.8rem' }}
              >
                {change > 0 ? '↑' : '↓'} {Math.abs(change).toFixed(1)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={inline ? "" : "card p-3 mb-4 shadow"}>
      <div className="row g-3 mb-4">
        <div className="col-12 col-md">
          <ScoreCard 
            label={t('overall')} 
            {...latestScores.overall} 
            color={colors.overall}
          />
        </div>
        <div className="col-6 col-md">
          <ScoreCard 
            label={t('fluency')} 
            {...latestScores.fluency}
            color={colors.fluency}
          />
        </div>
        <div className="col-6 col-md">
          <ScoreCard 
            label={t('vocabulary')} 
            {...latestScores.vocabulary}
            color={colors.vocabulary}
          />
        </div>
        <div className="col-6 col-md">
          <ScoreCard 
            label={t('grammar')} 
            {...latestScores.grammar}
            color={colors.grammar}
          />
        </div>
        <div className="col-6 col-md">
          <ScoreCard 
            label={t('pronunciation')} 
            {...latestScores.pronunciation}
            color={colors.pronunciation}
          />
        </div>
      </div>
      
      <div style={{ height: '300px' }}>
        {performanceData.length === 1 ? (
          <Bar data={chartData} options={options} />
        ) : (
          <Line data={chartData} options={options} />
        )}
      </div>
    </div>
  );
};

export default PerformanceChart; 