import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Transcript from '../components/Transcript';
import Feedback from '../components/Feedback';
import { useRealtime } from '../hooks/useRealtime';

const Part3Page = () => {
  const { t } = useTranslation();
  const { 
    isConnected, 
    items, 
    feedback,
    connect, 
    disconnect, 
    cleanupResources 
  } = useRealtime();

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [cleanupResources]);

  const handleToggleRecording = () => {
    if (isConnected) {
      disconnect();
    } else {
      connect('3');
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">{t('part_3_discussion')}</h1>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <button 
                  onClick={handleToggleRecording}
                  className={`btn ${isConnected ? 'btn-secondary' : 'btn-danger'} btn-lg mt-3 mb-3 px-4 fs-3`}
                >
                  {isConnected ? t('stop_exercise') : t('start_exercise')}
                </button>
              </div>
              {items.length > 0 && <Transcript 
                conversation={items.map(item => ({
                  speaker: item.role === 'assistant' ? 'Examiner' : 'User',
                  text: item.formatted?.transcript || item.formatted?.text
                }))}
              />}
              {feedback && <Feedback feedback={feedback} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part3Page; 