import React, { useState, useEffect } from 'react';

const LoadingText = ({ text, dots = 3, interval = 500 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prev) => (prev + 1) % (dots + 1));
    }, interval);

    return () => clearInterval(timer);
  }, [dots, interval]);

  return (
    <span>
      {text}
      {'.'.repeat(count)}
    </span>
  );
};

export default LoadingText; 