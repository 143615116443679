import { useCallback, useRef, useState } from 'react';
import { AudioRecorder } from '../utils/AudioRecorder';
import { AudioPlayer } from '../utils/AudioPlayer';

// Utility functions moved from Part1Page
const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const chunkSize = 0x8000;
  for (let i = 0; i < bytes.length; i += chunkSize) {
    const chunk = bytes.subarray(i, i + chunkSize);
    binary += String.fromCharCode.apply(null, chunk);
  }
  return btoa(binary);
};

const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const useRealtime = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [cueCard, setCueCard] = useState(null);
  const [isWaitingForFeedback, setIsWaitingForFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);
  const wsRef = useRef(null);
  const wavRecorderRef = useRef(null);
  const wavStreamPlayerRef = useRef(null);
  const [pronunciations, setPronunciations] = useState(new Map());

  const cleanupResources = useCallback(() => {
    if (wsRef.current) { 
      wsRef.current.close(); 
      wsRef.current = null; 
    }
    if (wavRecorderRef.current) { 
      wavRecorderRef.current.quit(); 
      wavRecorderRef.current = null; 
    }
    if (wavStreamPlayerRef.current) { 
      wavStreamPlayerRef.current = null; 
    }
    setIsWaitingForFeedback(false);
  }, []);

  const requestFeedback = useCallback(() => {
    if (wavRecorderRef.current) { 
      wavRecorderRef.current.quit(); 
      wavRecorderRef.current = null; 
    }

    if (wsRef.current?.readyState === WebSocket.OPEN) {
      setIsWaitingForFeedback(true);
      wsRef.current.send(JSON.stringify({
        type: 'request_feedback'
      }));
    }
  }, []);

  const disconnect = useCallback(() => {
    setIsConnected(false);
    cleanupResources();
  }, [cleanupResources]);

  const getCueCard = useCallback(async (topic = null) => {
    cleanupResources();
    setFeedback(null);
    setFeedbackError(null);
    setCueCard(null);

    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication required');
    }

    const wsUrl = process.env.NODE_ENV === 'production' 
      ? `wss://${window.location.host}?part=2&mode=cuecard&token=${token}&topic=${topic}` 
      : `ws://${window.location.hostname}:5001?part=2&mode=cuecard&token=${token}&topic=${topic}`;
    
    const ws = new WebSocket(wsUrl);

    try {
      await new Promise((resolve, reject) => {
        ws.addEventListener('open', resolve);
        ws.addEventListener('error', reject);
      });

      ws.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'cue_card') {
          setCueCard(message.data);
        }
      });
    } catch (error) {
      console.error('Error getting cue card:', error);
    }
  }, [cleanupResources]);

  const connect = useCallback(async (part = '1', topic = null) => {
    cleanupResources();
    setFeedback(null);
    setFeedbackError(null);
    
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication required');
    }

    try {
      // First initialize the recorder and get microphone permissions
      wavRecorderRef.current = new AudioRecorder({ sampleRate: 24000 });
      await wavRecorderRef.current.begin();

      // Initialize and connect the audio player before WebSocket connection
      wavStreamPlayerRef.current = new AudioPlayer({ sampleRate: 24000 });
      await wavStreamPlayerRef.current.connect();

      // Only after audio setup is complete, establish WebSocket connection
      const wsUrl = process.env.NODE_ENV === 'production' 
        ? `wss://${window.location.host}?part=${part}&token=${token}&topic=${topic}` 
        : `ws://${window.location.hostname}:5001?part=${part}&token=${token}&topic=${topic}`;
      
      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      await new Promise((resolve, reject) => {
        ws.addEventListener('open', resolve);
        ws.addEventListener('error', reject);
      });

      ws.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);
        
        switch (message.type) {
          case 'cue_card':
            setCueCard(message.data);
            break;
          case 'audio_delta':
            const { item_id, delta } = message;
            if (delta) {
              const audioData = new Int16Array(base64ToArrayBuffer(delta));
              wavStreamPlayerRef.current?.add16BitPCM(audioData, item_id);
            }
            break;
          case 'feedback':
            const parsedFeedback = message.feedback;
            setFeedback(parsedFeedback);
            // setIsWaitingForFeedback(false);
            // disconnect();
            break;
          case 'wait_for_feedback':
            if (wavRecorderRef.current) { 
              wavRecorderRef.current.quit(); 
              wavRecorderRef.current = null; 
            }
            setIsWaitingForFeedback(true);
            break;
          case 'feedback_error':
            setFeedbackError('An error occurred, please try again.');
            disconnect();
            break;
          case 'pronunciation_audio':
            setPronunciations(prev => new Map(prev).set(
              message.audio_id, 
              message.audio
            ));
            break;
          default:
            console.warn('Unhandled message type:', message.type);
            break;
        }
      });

      ws.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
        disconnect();
      });

      ws.addEventListener('close', () => {
        disconnect();
      });

      await wavRecorderRef.current.record((data) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({
            type: 'audio_buffer',
            audio: arrayBufferToBase64(data.mono)
          }));
        }
      });

      setIsConnected(true);
    } catch (error) {
      console.error('Error connecting:', error);
      disconnect();
    }
  }, [cleanupResources, disconnect]);

  return {
    isConnected,
    feedback,
    setFeedback,
    cueCard,
    isWaitingForFeedback,
    feedbackError,
    connect,
    disconnect,
    cleanupResources,
    getCueCard,
    requestFeedback,
    pronunciations,
    setPronunciations
  };
}; 