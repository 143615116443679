export class AudioRecorder {
  constructor({ sampleRate = 24000 } = {}) {
    this.mediaRecorder = null;
    this.stream = null;
    this.audioContext = null;
    this.analyser = null;
    this.dataCallback = null;
    this.sampleRate = sampleRate;
    this.processor = null;
  }

  async begin() {
    try {
      // Request microphone access with specific constraints
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: this.sampleRate
        }
      });

      // Create audio context and analyzer
      this.audioContext = new AudioContext({
        sampleRate: this.sampleRate
      });

      const source = this.audioContext.createMediaStreamSource(this.stream);
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 2048;

      // Create ScriptProcessor for real-time processing
      // Note: While ScriptProcessor is deprecated, it's still more widely supported than AudioWorklet
      this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);
      
      // Connect the audio nodes
      source.connect(this.analyser);
      this.analyser.connect(this.processor);
      this.processor.connect(this.audioContext.destination);

      return true;
    } catch (error) {
      console.error('ModernRecorder begin error:', error);
      this.quit();
      throw error;
    }
  }

  async record(callback) {
    if (!this.processor) {
      throw new Error('Recorder not initialized. Call begin() first.');
    }

    this.dataCallback = callback;

    // Process audio data in real-time
    this.processor.onaudioprocess = (e) => {
      const inputData = e.inputBuffer.getChannelData(0);
      
      // Convert Float32Array to Int16Array for compatibility
      const intData = new Int16Array(inputData.length);
      for (let i = 0; i < inputData.length; i++) {
        // Convert Float32 to Int16
        const s = Math.max(-1, Math.min(1, inputData[i]));
        intData[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
      }

      // Send the data through the callback
      if (this.dataCallback) {
        this.dataCallback({
          mono: intData.buffer,
          raw: intData.buffer
        });
      }
    };

    return true;
  }

  quit() {
    if (this.processor) {
      this.processor.disconnect();
      this.processor = null;
    }
    
    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = null;
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }

    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }

    this.dataCallback = null;
    return true;
  }
}