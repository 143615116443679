const highlightCorrections = (text) => {
  const parts = text.split(/(<good>.*?<\/good>|<bad>.*?<\/bad>|<example>.*?<\/example>)/g); // Split at both <ins> and <del> tags
  return parts.map((part, index) => {
    if (part.startsWith("<good>") && part.endsWith("</good>")) {
      return (
        <>
          {" "}
          <span key={index} style={{ color: "#006400", textDecoration: "underline" }}>
            {part.replace(/<\/?good>/g, "")}
          </span>
        </>
      );
    } else if (part.startsWith("<bad>") && part.endsWith("</bad>")) {
      return (
        <>
          {" "}
          <span key={index} style={{ color: "#b22222", textDecoration: "underline" }}>
            {part.replace(/<\/?bad>/g, "")}
          </span>
        </>
      );
    } else if (part.startsWith("<example>") && part.endsWith("</example>")) {
      return (
        <>
          {" "}
          <span key={index} style={{ color: "#2f56bc", textDecoration: "underline" }}>
            {part.replace(/<\/?example>/g, "")}
          </span>
        </>
      );
    }
    return part; // Return the plain text if no tags
  });
};

export default highlightCorrections;
