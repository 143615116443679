import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation for translations
import { 
  updateTargetLanguage, 
  updateProficiencyLevel, 
  updateTopic, 
  updateKnownLanguage,
  updateExerciseTypes 
} from '../services/userService';
import languages from '../config/languages';
import styles from '../styles/SettingsPage.module.css';

const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const { user, setUser, refreshUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    targetLanguage: user?.targetLanguage || 'EN',
    proficiencyLevel: user?.proficiencyLevel || 'A1',
    topic: user?.topic || 'general',
    knownLanguage: user?.knownLanguage || 'EN',
    exerciseTypes: user?.exerciseTypes || ['reading', 'listening', 'writing', 'speaking']
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch subscription status on mount
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/api/user/subscription-status', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  // Generic field update handler
  const handleFieldUpdate = async (field, value, updateFunction) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await updateFunction(value, token);
      
      setFormData(prev => ({ ...prev, [field]: value }));
      setUser(prev => ({ ...prev, [field]: value }));
      
      if (field === 'knownLanguage') {
        i18n.changeLanguage(value.toLowerCase());
        localStorage.setItem('language', value);
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updateFunctions = {
      targetLanguage: updateTargetLanguage,
      proficiencyLevel: updateProficiencyLevel,
      topic: updateTopic,
      knownLanguage: updateKnownLanguage
    };
    
    handleFieldUpdate(id, value, updateFunctions[id]);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/user/cancel-subscription', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubscriptionStatus('cancelled');
      refreshUser();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/premium');
  };

  // Add handler for exercise types changes
  const handleExerciseTypesChange = (type) => {
    setFormData(prev => {
      const updatedTypes = prev.exerciseTypes.includes(type)
        ? prev.exerciseTypes.filter(t => t !== type)
        : [...prev.exerciseTypes, type];
      
      // Ensure at least one type is selected
      if (updatedTypes.length === 0) {
        return prev;
      }
      
      handleFieldUpdate('exerciseTypes', updatedTypes, updateExerciseTypes);
      return { ...prev, exerciseTypes: updatedTypes };
    });
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">{t('settings')}</h1>
      
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {/* User Info Section */}
              <div className="text-center my-3">
                <p className="fs-4 mb-1">{user.name}</p>
                <p className="text-muted mb-4 fs-6">{user.email}</p>
                
                {/* Subscription Status */}
                {subscriptionStatus === 'active' ? (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                    <img 
                      src={premiumImage} 
                      alt={t('premium_subscription')} 
                      style={{ height: '20px' }}
                      className="mb-3 mb-md-0" 
                    />
                    <button
                      type="button"
                      className="btn btn-danger mx-md-3 rounded-pill px-5"
                      onClick={handleCancelSubscription}
                      disabled={loading}
                    >
                      {t('cancel_subscription')}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                    <img 
                      src={basicImage} 
                      alt={t('basic_subscription')} 
                      style={{ height: '18px' }}
                      className="mb-3 mb-md-0" 
                    />
                    <button
                      type="button"
                      className="btn btn-success mx-md-3 rounded-pill px-5"
                      onClick={handleSubscribe}
                      disabled={loading}
                    >
                      {t('upgrade')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-4 mb-4 text-center">
        <button 
          className="btn btn-primary rounded-pill px-5" 
          onClick={() => navigate('/')}
          style={{ fontSize: '1.15rem' }}
        >
          <i className="bi bi-reply me-1"></i> {t('back')}
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
