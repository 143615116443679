import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import styles from '../styles/USPs.module.css'; // Import the CSS module
import 'bootstrap-icons/font/bootstrap-icons.css';

const USPs = () => {
  const { t } = useTranslation(); // Initialize the translation function

  const usps = [
    { icon: 'bi-mic-fill', text: t('usp_practice'), link: '#exam-practice' },
    { icon: 'bi-chat-dots-fill', text: t('usp_feedback'), link: '#ai-feedback' },
    { icon: 'bi-bar-chart-fill', text: t('usp_score'), link: '#score-prediction' },
  ];

  return (
    <div className="container text-center mt-2 mb-4 px-0" style={{ maxWidth: '720px' }}>
      <div className="row row-cols-3 row-cols-md-5 g-2 g-md-2 justify-content-center">
        {usps.map((usp, index) => (
          <div key={index} className="col">
            <a href={usp.link} className="text-decoration-none">
              <div className={styles.uspItem}>
                <i className={`bi ${usp.icon} ${styles.icon}`}></i>
                <p className={`${styles.text} mb-0 fs-6`}>{usp.text}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default USPs;
