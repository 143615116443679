import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from '../styles/ResetPasswordPage.module.css';
import logo from '../assets/logo_with_mic_black.png';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      setError(t('invalid_reset_token'));
      return;
    }
  }, [token, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError(t('passwords_do_not_match'));
      return;
    }

    if (password.length < 6) {
      setError(t('password_too_short'));
      return;
    }

    try {
      await axios.post('/api/auth/reset-password', {
        token,
        password
      });
      
      // Redirect to login page immediately with success parameter
      navigate('/login?passwordReset=success');
    } catch (error) {
      setError(error.response?.data?.message || t('reset_password_error'));
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signin w-100 m-auto ${styles.formSignin}`}>
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="110" />
          <h1 className="h3 mb-3 fw-normal">{t('reset_password')}</h1>

          {status && (
            <div className="alert alert-success" role="alert">
              {status}
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className={`form-control ${styles.passwordInput}`}
              id="floatingPassword"
              placeholder={t('new_password_label')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">{t('new_password_label')}</label>
          </div>

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className={`form-control ${styles.confirmPasswordInput}`}
              id="floatingConfirmPassword"
              placeholder={t('confirm_password_label')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingConfirmPassword">{t('confirm_password_label')}</label>
          </div>

          <button className="btn btn-primary w-100 py-2 mt-3" type="submit">
            {t('reset_password')}
          </button>
          <p className="mt-5 mb-3 text-body-secondary">© 2025 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default ResetPasswordPage; 