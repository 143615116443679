import React from 'react';
import { useTranslation } from 'react-i18next';
import highlightCorrections from '../utils/highlightCorrections';
import PerformanceChart from './PerformanceChart';

const ScoreBar = ({ score }) => {
  const getColor = (score) => {
    if (score >= 7) return '#90EE90'; // Light green
    if (score >= 5) return '#FFB74D'; // Light orange
    return '#FF8A80'; // Light red
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="progress flex-grow-1" style={{ height: '5px', backgroundColor: '#e8ecf7' }}>
        <div 
          className="progress-bar"
          role="progressbar"
          style={{ 
            width: `${(score/9)*100}%`,
            transition: 'width 0.5s ease-out',
            backgroundColor: getColor(score)
          }}
          aria-valuenow={score}
          aria-valuemin="0"
          aria-valuemax="9"
        />
      </div>
    </div>
  );
};

const OverallFeedback = ({ feedback }) => {
  const { t } = useTranslation();
  const { score, summary } = feedback.overall;

  return (
    <div className="mb-4 card shadow">
      <div className="px-3 d-flex flex-column align-items-center text-center py-3" 
        style={{ 
          backgroundColor: '#2f56bc', 
          borderRadius: '0.5rem 0.5rem 0 0', 
          color: 'white' 
        }}>
        <div className="text-white fs-2 fw-semibold">
          {t('you_scored')} {score}<span style={{ color: 'rgba(255, 255, 255, 0.75)' }}>/9</span>
        </div>
      </div>
      <ScoreBar score={score} />
      <div style={{ 
        backgroundColor: 'white', 
        padding: '1.5rem', 
        borderRadius: '0 0 0.5rem 0.5rem' 
      }}>
        <div className="text-body-secondary text-center mb-4" style={{ fontSize: '1.1rem' }}>
          {summary}
        </div>
        <PerformanceChart resetTrigger={feedback} inline={true} />
      </div>
    </div>
  );
};

const AudioPlayer = ({ audio }) => {
  if (!audio) return null;

  const playAudio = () => {
    const audioObj = new Audio(`data:audio/mp3;base64,${audio}`);
    audioObj.play();
  };
  
  return (
    <button 
      onClick={playAudio}
      className="btn btn-link p-0"
      style={{ 
        cursor: 'pointer',
        color: 'white',
        transition: 'all 0.2s ease',
        border: 'none',
        background: '#2f56bc',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        minWidth: '24px',
        minHeight: '24px',
        lineHeight: 1,
        borderRadius: '50%',
        marginLeft: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: 0,
        textDecoration: 'none'
      }}
      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
    >
      <i className="bi bi-volume-up-fill" style={{ 
        fontSize: '0.875rem',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none'
      }}></i>
    </button>
  );
};

const FeedbackCategory = ({ category, icon, categoryData, pronunciations }) => {
  const { t } = useTranslation();
  const { score } = categoryData;

  const formatContent = (text) => {
    if (text.startsWith('"') && text.endsWith('"')) {
      return text;
    }
    return `"${text}"`;
  };

  // Create arrays from numbered fields
  const strengths = [
    { point: categoryData.strength1.point, example: categoryData.strength1.example },
    { point: categoryData.strength2.point, example: categoryData.strength2.example }
  ];

  const improvements = [
    { 
      point: categoryData.improve1.point, 
      example: categoryData.improve1.example,
      better_example: categoryData.improve1.better
    },
    {
      point: categoryData.improve2.point,
      example: categoryData.improve2.example,
      better_example: categoryData.improve2.better
    }
  ];

  const suggestions = [
    { point: categoryData.tip1.point, example: categoryData.tip1.example },
    { point: categoryData.tip2.point, example: categoryData.tip2.example }
  ];

  return (
    <div className="mb-3 card shadow">
      <div className="px-3 d-flex flex-column flex-md-row align-items-md-center gap-3 row-gap-2" 
        style={{ 
          backgroundColor: '#2f56bc', 
          paddingTop: '0.75rem', 
          paddingBottom: '0.75rem', 
          borderRadius: '0.5rem 0.5rem 0 0', 
          color: 'white' 
        }}>
        <div className="d-flex align-items-center gap-3">
          <i className={`bi ${icon} text-white`}></i>
          <span className="text-white">{t(category)}</span>
          <span className="text-white opacity-75">
            {score}<span style={{ color: 'rgba(255, 255, 255, 0.75)' }}>/9</span>
          </span>
        </div>
      </div>
      <ScoreBar score={score} />
      <div style={{ backgroundColor: 'white', padding: '1rem', borderRadius: '0 0 0.5rem 0.5rem' }}>
        <div className="mb-4">
          {strengths.map((item, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex gap-3 mb-2 d-md-none">
                <div className="d-flex flex-column align-items-center">
                  <i className="bi bi-check-circle-fill" style={{ color: '#2E7D32' }}></i>
                </div>
                <div className="flex-grow-1">
                  <span style={{ color: '#1B5E20' }}>{highlightCorrections(item.point)}</span>
                </div>
              </div>
              <div className="row d-none d-md-flex" style={{ alignItems: 'stretch', marginBottom: index < strengths.length - 1 ? '1rem' : 0 }}>
                <div className="col-md-5">
                  <div className="d-flex gap-3 p-2" style={{ minHeight: '48px' }}>
                    <div className="d-flex flex-column align-items-center">
                      <i className="bi bi-check-circle-fill" style={{ color: '#2E7D32' }}></i>
                    </div>
                    <div className="flex-grow-1">
                      <span style={{ color: '#1B5E20' }}>{highlightCorrections(item.point)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="p-2 rounded-2 d-flex align-items-center gap-2" style={{ backgroundColor: '#f8faf8', minHeight: '48px' }}>
                    <span style={{ color: '#2e7d32', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                    {category === 'pronunciation' && (
                      <AudioPlayer audio={pronunciations.get(`pronunciation_strength${index + 1}_example`)} />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-md-none">
                <div className="p-2 rounded-2 d-flex align-items-center gap-2" style={{ backgroundColor: '#f8faf8', minHeight: '48px' }}>
                  <span style={{ color: '#2e7d32', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                  {category === 'pronunciation' && (
                    <AudioPlayer audio={pronunciations.get(`pronunciation_strength${index + 1}_example`)} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mb-4">
          {improvements.map((item, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex gap-3 mb-2 d-md-none">
                <div className="d-flex flex-column align-items-center">
                  <i className="bi bi-exclamation-circle-fill" style={{ color: '#C62828' }}></i>
                </div>
                <div className="flex-grow-1">
                  <span style={{ color: '#B71C1C' }}>{highlightCorrections(item.point)}</span>
                </div>
              </div>
              <div className="row d-none d-md-flex" style={{ alignItems: 'stretch', marginBottom: index < improvements.length - 1 ? '2rem' : 0 }}>
                <div className="col-md-5">
                  <div className="d-flex gap-3 p-2" style={{ minHeight: '48px' }}>
                    <div className="d-flex flex-column align-items-center">
                      <i className="bi bi-exclamation-circle-fill" style={{ color: '#C62828' }}></i>
                    </div>
                    <div className="flex-grow-1">
                      <span style={{ color: '#B71C1C' }}>{highlightCorrections(item.point)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex flex-column gap-0">
                    <div className="p-2 rounded-2 d-flex align-items-center gap-2 position-relative" style={{ backgroundColor: '#faf8f8', minHeight: '48px' }}>
                      <span style={{ color: '#c62828', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                      {category === 'pronunciation' && (
                        <AudioPlayer audio={pronunciations.get(`pronunciation_improve${index + 1}_example`)} />
                      )}
                      {item.better_example && (
                        <div className="position-absolute" style={{ 
                          left: '50%', 
                          bottom: '-27px',
                          transform: 'translateX(-50%)',
                          zIndex: 1,
                          borderRadius: '50%',
                          padding: '4px'
                        }}>
                          <i className="bi bi-arrow-down" style={{ fontSize: '1.5rem', color: '#999' }}></i>
                        </div>
                      )}
                    </div>
                    {item.better_example && (
                      <div className="p-2 rounded-2 d-flex align-items-center gap-2 mt-2" style={{ backgroundColor: '#f8faf8', minHeight: '48px' }}>
                        <span style={{ color: '#2e7d32', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.better_example) }} />
                        {category === 'pronunciation' && (
                          <AudioPlayer audio={pronunciations.get(`pronunciation_improve${index + 1}_better`)} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-md-none">
                <div className="d-flex flex-column gap-0">
                  <div className="p-2 rounded-2 d-flex align-items-center gap-2 position-relative" style={{ backgroundColor: '#faf8f8', minHeight: '48px' }}>
                    <span style={{ color: '#c62828', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                    {category === 'pronunciation' && (
                      <AudioPlayer audio={pronunciations.get(`pronunciation_improve${index + 1}_example`)} />
                    )}
                    {item.better_example && (
                      <div className="position-absolute" style={{ 
                        left: '50%', 
                        bottom: '-27px',
                        transform: 'translateX(-50%)',
                        zIndex: 1,
                        borderRadius: '50%',
                        padding: '4px'
                      }}>
                        <i className="bi bi-arrow-down" style={{ fontSize: '1.5rem', color: '#999' }}></i>
                      </div>
                    )}
                  </div>
                  {item.better_example && (
                    <div className="p-2 rounded-2 d-flex align-items-center gap-2 mt-2" style={{ backgroundColor: '#f8faf8', minHeight: '48px' }}>
                      <span style={{ color: '#2e7d32', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.better_example) }} />
                      {category === 'pronunciation' && (
                        <AudioPlayer audio={pronunciations.get(`pronunciation_improve${index + 1}_better`)} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          {suggestions.map((item, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex gap-3 mb-2 d-md-none">
                <div className="d-flex flex-column align-items-center">
                  <i className="bi bi-lightbulb-fill" style={{ color: '#1565C0' }}></i>
                </div>
                <div className="flex-grow-1">
                  <span style={{ color: '#0D47A1' }}>{highlightCorrections(item.point)}</span>
                </div>
              </div>
              <div className="row d-none d-md-flex" style={{ alignItems: 'stretch', marginBottom: index < suggestions.length - 1 ? '1rem' : 0 }}>
                <div className="col-md-5">
                  <div className="d-flex gap-3 p-2" style={{ minHeight: '48px' }}>
                    <div className="d-flex flex-column align-items-center">
                      <i className="bi bi-lightbulb-fill" style={{ color: '#1565C0' }}></i>
                    </div>
                    <div className="flex-grow-1">
                      <span style={{ color: '#0D47A1' }}>{highlightCorrections(item.point)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="p-2 rounded-2 d-flex align-items-center gap-2" style={{ backgroundColor: '#f8f9fa', minHeight: '48px' }}>
                    <span style={{ color: '#1565C0', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                    {category === 'pronunciation' && (
                      <AudioPlayer audio={pronunciations.get(`pronunciation_tip${index + 1}_example`)} />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-md-none">
                <div className="p-2 rounded-2 d-flex align-items-center gap-2" style={{ backgroundColor: '#f8f9fa', minHeight: '48px' }}>
                  <span style={{ color: '#1565C0', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: formatContent(item.example) }} />
                  {category === 'pronunciation' && (
                    <AudioPlayer audio={pronunciations.get(`pronunciation_tip${index + 1}_example`)} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Feedback = ({ feedback, pronunciations }) => {
  const { t } = useTranslation();

  if (!feedback) return null;

  const categories = [
    { key: 'fluency', icon: 'bi-chat-dots' },
    { key: 'vocabulary', icon: 'bi-book' },
    { key: 'grammar', icon: 'bi-pencil' },
    { key: 'pronunciation', icon: 'bi-bullseye' }
  ];

  return (
    <div>
      <OverallFeedback feedback={feedback} />

      {categories.map(({ key, icon }) => (
        <FeedbackCategory 
          key={key}
          category={key}
          icon={icon}
          categoryData={feedback[key]}
          pronunciations={pronunciations}
        />
      ))}
    </div>
  );
};

export default Feedback; 