import React from 'react';
import { useTranslation } from 'react-i18next';

const Error = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className="feedback-error bg-danger bg-opacity-10 p-4 rounded-4 mb-4 mx-auto" style={{ maxWidth: '350px' }}>
      <div className="d-flex align-items-center gap-3">
        <div className="error-icon">
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            className="text-danger"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
        </div>
        <div className="d-flex flex-column">
          {/* <h6 className="text-danger mb-1">{t('error_occurred')}</h6> */}
          <p className="text-danger mb-0" style={{ opacity: 0.8 }}>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Error; 