import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NotePad = () => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState('');

  return (
    <div className="card h-100 shadow" style={{ borderRadius: '1rem', overflow: 'hidden' }}>
      <div style={{ backgroundColor: '#f0c04c', padding: '1rem' }}>
        <div className="d-flex align-items-center">
          <h6 className="mb-0" style={{ color: '#5c4c1c' }}>
            <i className="bi bi-pencil me-2"></i>
            {t('notes')}
          </h6>
        </div>
      </div>
      <div className="flex-grow-1">
        <textarea
          className="form-control border-0 h-100 px-3"
          style={{
            minHeight: '200px',
            backgroundColor: '#fff9e6',
            lineHeight: '2.5',
            fontSize: '1rem',
            backgroundImage: 'repeating-linear-gradient(#fff9e6, #fff9e6 39px, #ffe5a3 39px, #ffe5a3 40px, #fff9e6 40px)',
            backgroundAttachment: 'local',
            color: '#2c2c2c',
          }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder={t('notes_placeholder')}
        />
      </div>
    </div>
  );
};

export default NotePad; 