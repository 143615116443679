const constants = {
  trialDays: 7,
  exerciseLimits: {
    basic: {
      part1: 1,
      part2: 1,
      part3: 1
    },
    premium: {
      part1: 10,
      part2: 10,
      part3: 10
    }
  },
  prices: {
    standard: '$19.99',
    discounted: '$9.99',
    discount: 50
  },
  topicCount: 27
};

export default constants;