import React, { useContext, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import SignUpNow from '../components/SignUpNow';
// import Testimonials from '../components/Testimonials';
import AboutUs from '../components/AboutUs';

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // References for all sections
  // const testimonialsRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutUsRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);

  // References for IELTS Speaking specific features
  const practiceRef = useRef(null);
  const scoreRef = useRef(null);
  const feedbackRef = useRef(null);

  // Redirect to /home if user is logged in
  // useEffect(() => {
  //   if (user) {
  //     navigate('/home');
  //   }
  // }, [user, navigate]);

  // Scroll to sections based on URL hash
  useEffect(() => {
    const scrollToSection = {
      // '#testimonials': testimonialsRef,
      '#features': featuresRef,
      '#about-us': aboutUsRef,
      '#pricing': pricingRef,
      '#faq': faqRef,
      '#exam-practice': practiceRef,
      '#score-prediction': scoreRef,
      '#ai-feedback': feedbackRef,
    };

    const ref = scrollToSection[location.hash];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <Hero />

      {/* Main Sections */}
      {/* <div ref={testimonialsRef}>
        <Testimonials />
      </div> */}

      {/* IELTS Speaking Features */}
      <div ref={featuresRef}>
        <Features
          practiceRef={practiceRef}
          scoreRef={scoreRef}
          feedbackRef={feedbackRef}
        />
      </div>

      {/* About Us Section */}
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>

      {/* Pricing Section */}
      <div ref={pricingRef}>
        <Pricing />
      </div>

      {/* FAQ Section */}
      <div ref={faqRef}>
        <FAQ />
      </div>

      {/* Call to Action */}
      <SignUpNow />
    </div>
  );
};

export default LandingPage;
