import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import styles from "../styles/FeedbackButton.module.css"; // Importing the CSS module

const FeedbackButton = () => {
  const { t } = useTranslation(); // Initialize translation function
  const { user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState(null); // For displaying success/error messages
  const [emailInput, setEmailInput] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
    setFeedbackStatus(null); // Reset status when opening modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (feedbackStatus === "success") {
      setFeedbackText(""); // Reset feedback text only after successful submission
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const submitFeedback = async () => {
    const trimmedMessage = feedbackText.trim();
    
    // Check for empty message
    if (!trimmedMessage) {
      setFeedbackStatus("empty");
      return;
    }

    // Check message length
    if (trimmedMessage.length < 10) {
      setFeedbackStatus("tooShort");
      return;
    }

    // Check maximum length
    if (trimmedMessage.length > 1000) {
      setFeedbackStatus("tooLong");
      return;
    }

    // Check email format for anonymous users
    if (!user && (!emailInput || !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))) {
      setFeedbackStatus("invalidEmail");
      return;
    }

    try {
      const endpoint = user 
        ? '/api/feedback/authenticated'
        : '/api/feedback/anonymous';

      const payload = {
        message: trimmedMessage,
        ...(user ? {} : { email: emailInput })
      };

      const headers = user 
        ? { Authorization: `Bearer ${localStorage.getItem('token')}` }
        : {};

      const response = await axios.post(endpoint, payload, { headers });

      if (response.status === 200) {
        setFeedbackStatus("success");
        setFeedbackText("");
        if (!user) {
          setEmailInput(""); // Clear email input on success for anonymous users
        }
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setFeedbackStatus("error");
    }
  };

  return (
    <>
      <button className={`btn btn-primary ${styles.feedbackButton}`} onClick={openModal}>
        <i className="bi bi-chat-dots me-2"></i>{t('feedback_button')}
      </button>

      {isModalOpen && (
        <div className={`modal fade show d-block ${styles.modalBackdrop}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('feedback_title')}</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {feedbackStatus !== "success" ? (
                  <>
                    <p className="text-muted mb-3">{t('feedback_description')}</p>
                    {!user && (
                      <div className="mb-3">
                        <input
                          type="email"
                          className={`form-control ${emailInput && !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? 'is-invalid' : ''}`}
                          placeholder={t('email_placeholder')}
                          value={emailInput}
                          onChange={(e) => setEmailInput(e.target.value)}
                          required
                          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        />
                        {emailInput && !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && (
                          <div className="invalid-feedback">
                            {t('invalid_email_format')}
                          </div>
                        )}
                      </div>
                    )}
                    <textarea
                      className="form-control"
                      value={feedbackText}
                      onChange={handleFeedbackChange}
                      placeholder={t('feedback_placeholder')}
                      rows="5"
                    ></textarea>
                  </>
                ) : (
                  <div className="alert alert-success m-0" role="alert">
                    {t('feedback_submitted')}
                  </div>
                )}
                {feedbackStatus === "error" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('feedback_error')}
                  </div>
                )}
                {feedbackStatus === "empty" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('feedback_empty_message')}
                  </div>
                )}
                {feedbackStatus === "tooShort" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('feedback_too_short_message')}
                  </div>
                )}
                {feedbackStatus === "tooLong" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('feedback_too_long_message')}
                  </div>
                )}
                {feedbackStatus === "invalidEmail" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('invalid_email_format')}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-primary rounded-pill px-4" onClick={closeModal}>
                  {t('close_button')}
                </button>
                {feedbackStatus !== "success" && (
                  <button type="button" className="btn btn-primary rounded-pill px-4" onClick={submitFeedback}>
                    {t('submit_button')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackButton;
