export class AudioPlayer {
  constructor({ sampleRate = 24000 } = {}) {
    this.audioContext = null;
    this.sampleRate = sampleRate;
    this.analyser = null;
    this.audioQueue = [];
    this.isPlaying = false;
    this.nextStartTime = 0;  // Track when the next audio should start
  }

  async connect() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext({
        sampleRate: this.sampleRate
      });
    }

    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 2048;
    this.analyser.connect(this.audioContext.destination);

    // Initialize nextStartTime
    this.nextStartTime = this.audioContext.currentTime;

    return true;
  }

  add16BitPCM(int16Data, itemId) {
    if (!this.audioContext) return false;

    // Convert Int16Array to Float32Array
    const float32Data = new Float32Array(int16Data.length);
    for (let i = 0; i < int16Data.length; i++) {
      float32Data[i] = int16Data[i] / 0x8000;
    }

    // Create audio buffer
    const audioBuffer = this.audioContext.createBuffer(1, float32Data.length, this.sampleRate);
    audioBuffer.getChannelData(0).set(float32Data);

    // Create and configure source
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(this.analyser);

    // Calculate start time with a small gap between chunks
    const startTime = Math.max(this.nextStartTime, this.audioContext.currentTime);
    const duration = audioBuffer.duration;
    this.nextStartTime = startTime + duration + 0.005; // 5ms gap between chunks

    // Start the audio immediately if it's the first in queue
    source.start(startTime);

    // Cleanup when this chunk ends
    source.onended = () => {
      source.disconnect();
    };

    return true;
  }

  getFrequencyData() {
    if (this.analyser) {
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(dataArray);
      return dataArray;
    }
    return null;
  }

  // Optional: Add method to stop all audio
  stop() {
    if (this.audioContext) {
      this.nextStartTime = this.audioContext.currentTime;
      this.audioContext.close();
      this.audioContext = null;
    }
  }
}