import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from '../styles/ForgotPasswordPage.module.css';
import logo from '../assets/logo_with_mic_black.png';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/forgot-password', { email });
      setStatus(response.data.message);
      setError('');
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred');
      setStatus('');
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signin w-100 m-auto ${styles.formSignin}`}>
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="110" />
          <h1 className="h3 mb-3 fw-normal">{t('forgot_password_title')}</h1>

          {status && (
            <div className="alert alert-success" role="alert">
              {status}
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className={`form-floating ${styles.formFloating} mb-3`}>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder={t('login_email_label')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">{t('login_email_label')}</label>
          </div>

          <button className="btn btn-primary w-100 py-2 rounded-pill" type="submit">
            {t('send_reset_link')}
          </button>
          <p className="mt-5 mb-3 text-body-secondary">© 2025 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default ForgotPasswordPage; 