import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import Feature from '../components/Feature';
// import exercisesFeatureImage from '../assets/listening_thumbnail.png';
// import essayFeatureImage from '../assets/writing_thumbnail.png';
// import speakingFeatureImage from '../assets/speaking_thumbnail.png';
// import languagesFeatureImage from '../assets/flags_thumbnail.png';
// import examPreparationImage from '../assets/table_completion_thumbnail.png';
// import interviewImage from '../assets/interview_thumbnail.png';
// import cueCardImage from '../assets/cue_card_thumbnail.png';
// import discussionImage from '../assets/discussion_thumbnail.png';
// import feedbackImage from '../assets/feedback_thumbnail.png';
// import analyticsImage from '../assets/analytics_thumbnail.png';
import examImage from '../assets/exam_thumbnail.png';
import feedbackImage from '../assets/feedback_thumbnail.png';
import scoreImage from '../assets/score_thumbnail.png';

const Features = ({
  practiceRef,
  scoreRef,
  feedbackRef,
}) => {
  const { t } = useTranslation();

  return (
    <div className="container col-xxl-9 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center mb-5">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('features_title')}</h2>
        <p className="fs-5 text-body-secondary">{t('features_subtitle')}</p>
      </div>
      <div ref={practiceRef}>
        <Feature
          heading={t('feature_practice_heading')}
          description={t('feature_practice_description')}
          image={examImage}
          buttonText={t('feature_practice_button')}
          textOnLeft={true}
        />
      </div>
      <div ref={feedbackRef}>
        <Feature
          heading={t('feature_feedback_heading')}
          description={t('feature_feedback_description')}
          image={feedbackImage}
          buttonText={t('feature_feedback_button')}
          textOnLeft={false}
        />
      </div>
      <div ref={scoreRef}>
        <Feature
          heading={t('feature_score_heading')}
          description={t('feature_score_description')}
          image={scoreImage}
          buttonText={t('feature_score_button')}
          textOnLeft={true}
          isLast={true}
        />
      </div>
    </div>
  );
};

export default Features;
