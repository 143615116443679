import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transcript from '../components/Transcript';
import Feedback from '../components/Feedback';
import CueCard from '../components/CueCard';
import NotePad from '../components/NotePad';
import Timer from '../components/Timer';
import { useRealtime } from '../hooks/useRealtime';

const Part2Page = () => {
  const { t } = useTranslation();
  const [isPreparing, setIsPreparing] = useState(false);
  const { 
    isConnected, 
    items, 
    feedback,
    cueCard,
    connect, 
    disconnect, 
    cleanupResources,
    getCueCard
  } = useRealtime();

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [cleanupResources]);

  const handleStartPreparation = async () => {
    await getCueCard();
    setIsPreparing(true);
  };

  const handlePreparationComplete = () => {
    setIsPreparing(false);
    connect('2');
  };

  const handleToggleRecording = () => {
    if (isConnected) {
      disconnect();
    } else {
      handleStartPreparation();
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">{t('part_2_cue_card')}</h1>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-4">
                {!isPreparing && !isConnected && (
                  <button 
                    onClick={handleToggleRecording}
                    className="btn btn-danger btn-lg px-4 fs-3"
                  >
                    {t('start_exercise')}
                  </button>
                )}
                {isConnected && (
                  <button 
                    onClick={handleToggleRecording}
                    className="btn btn-secondary btn-lg px-4 fs-3"
                  >
                    {t('stop_exercise')}
                  </button>
                )}
              </div>
              
              {cueCard?.topic && (
                <>
                  {isPreparing && (
                    <Timer 
                      duration={60} 
                      onComplete={handlePreparationComplete}
                    />
                  )}
                  <CueCard {...cueCard} />
                  <div className="mt-4">
                    <NotePad />
                  </div>
                </>
              )}

          
              <div className="card-body">
                {items.length > 0 && <Transcript 
                  conversation={items.map(item => ({
                    speaker: item.role === 'assistant' ? 'Examiner' : 'User',
                    text: item.formatted?.transcript || item.formatted?.text
                  }))}
                />}
                {feedback && <Feedback feedback={feedback} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part2Page;